import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "projects"
    }}>{`Projects`}</h1>
    <p>{`This page contains only content that has the tag `}<strong parentName="p">{`project`}</strong>{`.`}</p>
    <p>{`This works thanks to this code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<FeedItems filterByTags={['project']} />
`}</code></pre>
    <p>{`This is convenient for creating pages with your works, portfolio, collecting articles of a certain subject in one place, etc.`}</p>
    <FeedItems filterByTags={['project']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      